
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import CommsUpdates from "@/components/projects/comms/Updates.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

interface KTMessage {
  creator: string;
  created: string;
  body: string;
  replies: Array<any>;
}

export default defineComponent({
  name: "private-chat",
  components: {
    CommsUpdates,
    ckeditor: CKEditor.component
  },
  setup() {
    const messagesRef = ref<null | HTMLElement>(null);
    const commsUpdatesRef = ref<null | HTMLElement>(null);
    
    const route = useRoute();

    const messages = ref<Array<KTMessage>>([]);

    const newMessageText = ref("");

    const projects = ref([]);
    const currentProject = ref({});

    const isLoadingMessages = ref(false);

    let reloadTimer;

    const addNewMessage = () => {
      if (!newMessageText.value) {
        return;
      }

      pushMessage(newMessageText.value );

      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
        }
      }, 1);

      newMessageText.value = "";
    };

    const pushMessage = async (body) => {
      
      const payload = {
				data: {
          board_id: currentProject.value['board']['id'],
					item_id: currentProject.value['id'],
          body: body
				}
			};
			ApiService.setHeader();
			const resp = await ApiService.post("monday/board/item/updates/add", payload)
        .then(async ({ data }) => {
          pushUpdates(data, true);
          /*messages.value.push({
            creator: creator,
            created: created,
            body: body,
          });*/

				})
				.catch(({ response }) => {
          console.log(response);
          showAlert("Unable to connect to data source", "error", "OK");
				});
    };

    const isGroupChat = computed(() => {
      return route.path.indexOf("/group-chat") !== -1;
    });

    const onChangeProject = async (pid, event) => {
      event.preventDefault();

      currentProject.value = projects.value.filter(function(elem) {
          if (elem['id'] == pid) {
            return elem;
          }
        });
      currentProject.value = currentProject.value[0];

      isLoadingMessages.value = true;
      messages.value = [];
      await getUpdates();
      isLoadingMessages.value = false;

    };

    const getProjects = async () => {
      ApiService.setHeader();
			await ApiService.get("monday/board/items/all")
      .then(async ({ data }) => {
          projects.value = data.data;
          setCurrentProject(0);
				})
				.catch(({ response }) => {
          console.log(response);
          showAlert("Unable to connect to data source", "error", "OK");
				});
    };

    const setCurrentProject = async (idx) => {
      currentProject.value = projects.value[idx];
      isLoadingMessages.value = true;
      messages.value = [];
      await getUpdates();
      isLoadingMessages.value = false;
    };

    const pushUpdates = (data, isNew) => {
      const updates = data.updates;
      if (isNew) {
        for (const update in updates) {
          messages.value.push({
            creator: updates[update]['creator'],
            created: updates[update]['created'],
            body: updates[update]['body'],
            replies: updates[update]['replies'],
          });
        }
      }else{
        if (messages.value.length <= 0) {
          messages.value = [];
          for (const update in updates) {
            messages.value.push({
              creator: updates[update]['creator'],
              created: updates[update]['created'],
              body: updates[update]['body'],
              replies: updates[update]['replies'],
            });
          }
        }else {
          if (Object.keys(updates).length > messages.value.length) { 
            let ctr = 1;
            const messageCount = messages.value.length;
            for (const update in updates) {

              if (ctr > messageCount) {
                messages.value.push({
                  creator: updates[update]['creator'],
                  created: updates[update]['created'],
                  body: updates[update]['body'],
                  replies: updates[update]['replies'],
                });
              }
              ctr++;
            } 
          }
        }
      }
      
      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
        }
      }, 1);

    };

    const getUpdates = async() => {
      const payload = {
				data: {
          board_id: currentProject.value['board']['id'],
					item_id: currentProject.value['id'],
				}
			};
			ApiService.setHeader();
			const resp = await ApiService.post("monday/board/item/updates", payload)
        .then(async ({ data }) => {
          pushUpdates(data, false);
				})
				.catch(({ response }) => {
          console.log(response);
          showAlert("Unable to connect to data source", "error", "OK");
				});
    };

    const showAlert = (text, icon, button) =>{
      Swal.fire({
        text: text,
        icon: icon,
        buttonsStyling: false,
        confirmButtonText: button,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        //console.log('success');
      });
    };

    const editor = ClassicEditor;
    const editorConfig = ref({
        placeholder: 'Type your message here',
        removePlugins: ['imageUpload','mediaEmbed'],
        toolbar: [ 'Heading', 'Link', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ]
      });
    
    onMounted(() => {
      getProjects();
      
      setCurrentPageBreadcrumbs("Overview", ["Chat"]);

      if (!messagesRef.value) {
        return;
      }

      if (!reloadTimer) {
        reloadTimer = setInterval(getUpdates, 60000);
      }

      ScrollComponent.bootstrap();
    });

    return {
      messages,
      messagesRef,
      newMessageText,
      addNewMessage,
      commsUpdatesRef,
      isGroupChat,
      onChangeProject,
      projects,
      currentProject,
      isLoadingMessages,
      editor,
      editorConfig
    };
  },

});
