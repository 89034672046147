import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-start mb-10 ms-20" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = { class: "ms-3" }
const _hoisted_5 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
}
const _hoisted_6 = { class: "text-muted fs-7 mb-1 ms-10" }
const _hoisted_7 = {
  class: "p-5 rounded bg-light-info text-dark fw-bold text-start",
  "data-kt-element": "message-text"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.name), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.time), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", { innerHTML: _ctx.body }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}