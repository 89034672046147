
import { defineComponent, onMounted, ref } from "vue";
import CommsUpdateReplies from "@/components/projects/comms/UpdatesReplies.vue";

export default defineComponent({
  name: "comms-update-replies",
  components: {
    CommsUpdateReplies,
  },
  props: {
    name: String,
    time: String,
    body: String,
    replies: Array,
  },
  setup() {
    const commsUpdateRepliesRef = ref<null | HTMLElement>(null);

    return {
      commsUpdateRepliesRef
    }
  },
});
