
import { defineComponent } from "vue";
import CommsUpdates from "@/components/projects/comms/Updates.vue";

export default defineComponent({
  name: "comms-update",
  props: {
    name: String,
    time: String,
    body: String,
  },
});
